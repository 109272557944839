import React from "react"

const FeatureText = props => (
  <div
    style={{
      width: "auto",
      maxWidth: "750px",
      minWidth: "250px",
      position: "relative",
      float: "left",
      textAlign: "left",
      padding: "10px",
    }}
    className="feature-pages-text"
    {...props}
  />
)
const FeatureImage = props => (
  <div
    style={{
      height: "100%",
      width: "25%",
      minWidth: "250px",
      position: "relative",
      float: "left",
      padding: "10px",
      marginTop: "20px",
    }}
    className="feature-pages-image"
    {...props}
  />
)
const FeatureListItem = props => (
  <li
    style={{
      listStyleType: "disc",
      listStylePosition: "inside",
    }}
    {...props}
  />
)
const RowForward = props => (
  <div
    style={{
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px 0 40px 0",
    }}
    {...props}
  />
)
const RowReverse = props => (
  <div
    style={{
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row-reverse",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px 0 40px 0",
      borderTop: "3px solid lightgrey",
      borderBottom: "3px solid lightgrey",
    }}
    {...props}
  />
)

const MDXComponents = {
  FeatureText,
  FeatureImage,
  li: FeatureListItem,
  RowForward,
  RowReverse,
}

export default MDXComponents
